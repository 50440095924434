import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0761038b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress" }
const _hoisted_2 = {
  key: 0,
  class: "font-weight-bold"
}
const _hoisted_3 = {
  key: 1,
  class: "font-weight-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps({
      class: ["progress-bar text-center w-100 bg-transparent text-dark", _ctx.classes],
      style: { 'background-size': _ctx.progressBarPercentage }
    }, _ctx.$attrs), [
      (_ctx.showValue)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value) + " / " + _toDisplayString(_ctx.total), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.progressBarPercentage), 1))
    ], 16)
  ]))
}