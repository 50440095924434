
import { defineComponent, computed } from "vue";

export default defineComponent({
    props: {
        value: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        /**
         * Gets or sets the bootstrap variant for the progress bar
         */
        variant: {
            type: String,
            required: false,
            default: "primary"
        },
        /**
         * Gets or sets whether or not this progress bar should show a percentage or values
         */
        showValue: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        const progressBarPercentage = computed(
            () =>
                `${Math.floor(
                    ((props.value || 0) / (props.total || 1)) * 100
                )}%`
        );

        const classes = computed(() => {
            const classesObj: Record<string, boolean> = {};

            // Set background color
            classesObj["gradient-" + props.variant] = true;

            return classesObj;
        });

        return {
            progressBarPercentage,
            classes
        };
    }
});
